import { h, FunctionalComponent } from "preact"

const GuideSection: FunctionalComponent<{ id: string }> = ({
  id,
  children
}) => {
  return (
    <h3 id={id} class='pb-2 is-size-2'>
      {children}
    </h3>
  )
}

export default GuideSection
