import { FunctionalComponent, h } from "preact"
import GuidePage from "../../../components/page/GuidePage"
import ExternalLink from "../../../components/ExternalLink"
import MidContentAd from "../../../components/ads/MidContentAd"
import GuideContentSection from "../../../components/page/guide/GuideContentSection"
import GuideHeader from "../../../components/page/guide/GuideHeader"
import GuideContent from "../../../components/page/guide/GuideContent"
import IntroImage from "./intro.png"

const IntroToGTARPGuide: FunctionalComponent = () => {
  return (
    <GuidePage
      title='Getting Started with GTA Roleplay (RP)'
      description='Everything you need to know about FiveM / GTA RP'
    >
      <GuideContentSection>
        <GuideHeader title='Getting Started with Grand Theft Auto&reg; RP'>
          If you are just getting started with FiveM and GTA V roleplay, this
          guide explains how you can get started with joining a server, common
          terms, and the different parts of GTA roleplay.
        </GuideHeader>
        <br />

        <img src={IntroImage} alt='Getting Started with Grand Theft Auto RP' />
        <hr />

        <GuideContent id='what-is-rp' title='What Is GTA V RP?'>
          <p>
            GTA roleplay can best be explained as acting as a certain character
            in the game <strong>Grand Theft Auto V</strong>. GTA roleplay has
            become quite popular lately with streamers on platforms such as
            Twitch. It is one of the most-watched game categories, which for an
            8-year-old game is not bad!
          </p>
        </GuideContent>

        <GuideContent id='what-is-fivem' title='What is FiveM&reg;?'>
          <p>
            <strong>
              <ExternalLink href='https://fivem.net/'>FiveM</ExternalLink>&reg;
            </strong>{" "}
            is a game mod built on top of <strong>Grand Theft Auto V</strong>,
            which allows you to easily load scripts and other resources like
            vehicles and clothing automatically.
          </p>
          <p>
            It also allows you to connect to thousands of FiveM&reg; servers.
            Each server has a unique community that you can join and different
            kinds of servers with modifications.
          </p>
        </GuideContent>

        <GuideContent id='why-play-gta-roleplay?' title='Why play GTA RP?'>
          <p>
            GTA RP allows you to play as a variety of characters; whether you
            want to be a police officer or a criminal, a business owner or a
            politician, anything is possible in roleplay.
          </p>
        </GuideContent>

        <hr />
        <MidContentAd />
        <hr />

        <GuideContent id='about-characters' title='Characters' class='mb-6'>
          <p>
            Grand Theft Auto V&reg; and FiveM&reg; provide a lot of
            opportunities for unique characters. You can change their facial
            structure, hair, clothes, body, and much more – so making a unique
            character for you to become is easy!
          </p>
          <p>
            <strong>
              Each character you create can have a backstory, history, thoughts,
              emotions, and more.
            </strong>
          </p>
          <p>
            Many servers require that criminal personalities be a different
            character from those in departments, to prevent a conflict of
            interest between the department and their criminal activities. It
            would not look good for a police department that employs a drug
            dealer, or criminals in general.
          </p>
        </GuideContent>

        <div class='box'>
          <div class='columns is-multiline'>
            <div class='column is-6 p-5'>
              <h3 class='has-text-centered is-size-3' id='civilians'>
                🧔 Civilians
              </h3>
              <div class='is-size-5'>
                <p>
                  Civilians are characters in the server who are not affiliated
                  with any department. They can either be a normal, law-abiding
                  civilian with a regular job – or a criminal mastermind and
                  anything in-between.
                </p>
                <p>
                  <strong>
                    A regular working civilian character is a great way to get
                    started with roleplay and is likely required before applying
                    to a department.
                  </strong>
                </p>
              </div>
            </div>
            <div class='column is-6 p-5'>
              <h3 class='has-text-centered is-size-3' id='civilians'>
                👮‍♀️ Police
              </h3>
              <div class='is-size-5'>
                <p>
                  In traditional RP servers, the police department is often the
                  largest and the most important for keeping the balance between
                  civilians and the law.
                </p>
                <p>
                  <strong>
                    If you are quick thinking, can follow orders, and can work
                    within a team, then Law Enforcement might be right for you.
                  </strong>
                </p>
              </div>
            </div>
            <div class='column is-6 p-5'>
              <h3 class='has-text-centered is-size-3' id='civilians'>
                👨‍🚒 Firefighters
              </h3>
              <div class='is-size-5'>
                <p>
                  Firefighters provide the same essential role in roleplay as
                  they do in real life. Responding to fires in an organized
                  fashion is their primary function, but they also provide
                  medical services and first responder services.
                </p>
                <p>
                  <strong>
                    Firefighters are a highly organized department, emboldened
                    by pride, training, and organization.
                  </strong>
                </p>
              </div>
            </div>
            <div class='column is-6 p-5'>
              <h3 class='has-text-centered is-size-3' id='civilians'>
                👩‍⚕️️ EMS
              </h3>
              <div class='is-size-5'>
                <p>
                  And last but certainly not least, is the EMS (Emergency
                  Medical Services) department. Not all servers may have an EMS
                  department, but when they do, they will do their best to keep
                  you breathing.
                </p>
                <p>
                  <strong>
                    If you are quick thinking, and good at communicating, then
                    medical services may be a good department for you.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <MidContentAd />
        <hr />

        <GuideContent title='Important Concepts' id='important-concepts'>
          <GuideContent
            sub
            title='In Character (IC) &amp; Out Of Character (OOC)'
            id='staying-in-character'
            class='mt-4'
          >
            <p>
              An especially important concept of roleplay is staying{" "}
              <strong>In Character (IC)</strong>. This means putting yourself in
              your character's shoes. You are only aware of what your character
              is aware of, you feel your character's pain, emotions, thoughts,
              and much more. While RPing, you will always want to remain in
              character, unless you are explicitly talking&nbsp;
              <strong>Out Of Character (OOC)</strong>.
            </p>
            <p>
              <strong>Talking OOC while in-game is not allowed.</strong> This is
              called{" "}
              <strong>
                <dfn>breaking RP</dfn>
              </strong>{" "}
              and interrupts the ongoing conversation. This also ruins the RP
              for anyone else and is also a punishable event on servers.
            </p>
            <p>
              In most FiveM&reg; servers, you can talk in OOC chat by pressing
              the <kbd>Enter</kbd> key and putting <code>/ooc</code> before your
              message.
            </p>
          </GuideContent>

          <hr />
          {/* ------------- */}

          <GuideContent
            sub
            title='Doing Actions In-Character'
            id='doing-actions'
          >
            <p>
              <strong>
                Most FiveM&reg; servers also have a command to "do an action" as
                your character.
              </strong>
            </p>
            <p>
              For example, if you want to pick up a penny on the sidewalk, you
              would run the following command:
            </p>
            <pre>/me picks up a penny</pre>
            <p>
              These actions are always in the <strong>first person</strong>. It
              can be used to describe items you have, emotions you are feeling,
              and other things you may need to communicate while in character
              non-verbally.
            </p>
            <p>
              <strong>However, there are limits to these actions.</strong> Your
              actions are limited to your character,{" "}
              <strong>
                you cannot tell others what to do, or what will happen
              </strong>{" "}
              &mdash; This is called{" "}
              <strong>
                <dfn>power-gaming</dfn>
              </strong>
              . This doesn't allow the other player to respond or contribute to
              the roleplay.
            </p>
          </GuideContent>

          <hr />
          <MidContentAd />
          <hr />
          {/* ------------- */}

          <GuideContent
            sub
            title='Naming Your Character'
            id='naming-your-character'
          >
            <p>
              Your character will need a name, and the possibilities are
              endless! You can make it as realistic or funny as you want, but
              there are typically a few common rules that servers enforce:
              <strong>
                <ul>
                  <li>You can't use the names of celebrities</li>
                  <li>Avoid the names of religious figures</li>
                  <li>It must be somewhat unique</li>
                  <li>It must be... a name</li>
                </ul>
              </strong>
            </p>
            <p>
              As long as you follow these guidelines (and common sense), all you
              have to do is come up with a creative name for the personality you
              want to create.
            </p>
          </GuideContent>

          <hr />

          {/*<h3 class='is-size-3' id='new-life-rule'>*/}
          {/*  New Life Rule (NLR)*/}
          {/*</h3>*/}
          {/*<p class='is-size-5'>TODO</p>*/}

          <GuideContent sub title='CAD Systems' id='cad-systems'>
            <p>
              A{" "}
              <strong>
                <dfn>CAD (Computer Aided Dispatch)</dfn>
              </strong>{" "}
              system is commonplace in GTA RP servers. It is a system used to
              keep track of virtual citizens, just as a real government system
              would. It's also used to organize the dispatching of emergency
              services to calls and to store data about your driver's license,
              and past criminal history is stored.
            </p>
            <p>
              You will likely be required to register your character in a CAD
              system, even for civilian characters. If you fail to register, you
              may be fined or even arrested in-game while playing, as you have
              no "legal" ID.
            </p>
          </GuideContent>
          {/* ------------- */}
        </GuideContent>

        <hr />
        <MidContentAd />
        <hr />

        <GuideContent title='Finding A Server' id='finding-a-server'>
          <strong>
            FiveM&reg; has a dedicated section on their forums called the{" "}
            <ExternalLink href='https://forum.cfx.re/c/server-development/server-bazaar/38'>
              Server Bazaar
            </ExternalLink>
          </strong>{" "}
          where you can find a variety of servers that are looking for new
          members. Find a community that you can enjoy playing in, and you're
          guaranteed to have a great time! Whether you want to play on a popular
          server or a small community &mdash; you can find it in the Bazaar.
        </GuideContent>

        <hr />

        <GuideContent title="That's It!" id='thats-it'>
          Those are the basics of GTA roleplay. It's easy to find a server to
          play on with{" "}
          <ExternalLink href='https://fivem.net/'>FiveM&reg;</ExternalLink>, so
          get started! And most important of all, don't forget to have fun and
          enjoy yourself!
        </GuideContent>

        <hr />
        <MidContentAd />
      </GuideContentSection>
    </GuidePage>
  )
}

export default IntroToGTARPGuide
