import { FunctionalComponent, h } from "preact"

const QuestionDescription: FunctionalComponent = ({ children }) => {
  return (
    <div class='content'>
      <p class='is-size-4'>
        <strong>Description:</strong>{" "}
        <span itemProp='abstract'>{children}</span>
      </p>
    </div>
  )
}

export default QuestionDescription
