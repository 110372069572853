import { FunctionalComponent, h } from "preact"
import AdSlot from "./AdSlot"

const MidContentAd: FunctionalComponent = () => {
  let currentPath = "/"
  if (typeof window !== "undefined") {
    currentPath = window.location.pathname
  }
  return (
    <AdSlot
      currentPath={currentPath}
      id={9822389556}
    />
  )
}

export default MidContentAd
