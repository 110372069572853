import SolutionPage from "../../components/page/SolutionPage"
import FailedPackfileReadBulkFailedSolution from "../../components/page/solution/common-errors/FailedPackfileReadBulkFailedSolution"
import { FunctionalComponent, h } from "preact"
import LongAnswer from "../../components/page/solution/LongAnswer"

const PackfileSolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title="How to fix 'Failed to open packfile'"
      description='This is caused by either your network or the server'
    >
      <br />
      <br />
      <h2 class='is-size-2'>
        <strong>The 'Failed to open packfile' error</strong>
      </h2>
      <LongAnswer>
        This is a generic error for when{" "}
        <strong>server resources cannot be loaded</strong>. There are a variety
        of causes and different errors. Find the error below that matches what
        you are experiencing, in order to resolve it.
      </LongAnswer>

      <FailedPackfileReadBulkFailedSolution />
    </SolutionPage>
  )
}

export default PackfileSolutionPage
