import { FunctionalComponent, h } from "preact"
import StandardResponsivePageAd from "../ads/StandardResponsivePageAd"

interface Props {
  pre?: JSX.Element | JSX.Element[]
  hideAd?: boolean
}

const Page: FunctionalComponent<Props> = ({ children, hideAd, pre }) => {
  return (
    <main>
      {/* Render things outside of the container */}
      {pre}

      {/* Top of page banner ad */}
      {!hideAd && (
        <div>
          <br />
          <StandardResponsivePageAd />
        </div>
      )}

      {/* Now we do the page layout */}
      <div class='container'>{children}</div>
    </main>
  )
}

export default Page
