import { FunctionalComponent, h } from "preact"

type Props = { title: string }
const GuideHeader: FunctionalComponent<Props> = ({ title, children }) => {
  return (
    <div>
      <h2 class='is-size-1'>{title}</h2>
      <p class='is-size-4'>{children}</p>
    </div>
  )
}

export default GuideHeader
