import { FunctionalComponent, h } from "preact"
import SolutionPage from "../../components/page/SolutionPage"
import CanarySolution from "../../components/page/solution/common-errors/CanarySolution"

const CanarySolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title='Switching to Release Channel'
      description='You should not regularly play on the canary release channel'
    >
      <CanarySolution />
    </SolutionPage>
  )
}

export default CanarySolutionPage
