import { FunctionalComponent, h } from "preact"
import Question from "../Question"
import QuestionDescription from "../QuestionDescription"
import { Solution } from "../types"
import SolutionBox from "../answer/SolutionBox"
import LongAnswer from "../LongAnswer"
import DoNotBlameFiveM from "../DoNotBlameFiveM"
import SolutionArea from "../answer/SolutionArea"
import Symptoms from "../answer/Symptoms"
import Solutions from "../answer/Solutions"
import SolutionList from "../answer/SolutionList"
import SolutionSubList from "../answer/SolutionSubList"
import FiveMInstallDirectoryTable from "../table/FiveMInstallDirectoryTable"

const D3d11Solution: FunctionalComponent = () => {
  return (
    <Question
      title='Crashes involving d3d11.dll'
      id={Solution.D3D11_DLL}
      pre={<DoNotBlameFiveM />}
    >
      <QuestionDescription>
        A graphics mod is crashing your game
      </QuestionDescription>
      <LongAnswer>
        If you are experiencing crashes with <code>d3d11.dll</code>, removing it
        will get you playing FiveM&reg; again.{" "}
        <strong>
          <code>d3d11.dll</code> is not a file included with GTA V&reg; or
          FiveM&reg;
        </strong>
        , but a file included in many graphics mods.
      </LongAnswer>

      <SolutionArea>
        <SolutionBox>
          <Symptoms />
          <SolutionList
            items={[
              <span>
                <strong>Crashes mentioning:</strong>
                <br />
                <SolutionSubList
                  items={[
                    <code>d3d11.dll</code>,
                    <code>d3d11.dll?EnsureChildDevice@...</code>
                  ]}
                />
              </span>
            ]}
          />
        </SolutionBox>
        <SolutionBox>
          <Solutions />
          <SolutionList
            items={[
              <span>Close any FiveM&reg; processes</span>,
              <span>
                Locate your FiveM&reg; directory:
                <br />
                <FiveMInstallDirectoryTable style={{ marginLeft: "-2.5rem" }} />
              </span>,
              <span>
                <strong>Remove any of the following files</strong> from your GTA
                V&reg; directory:
                <br />
                <SolutionSubList
                  items={[
                    <code>d3d11.dll</code>,
                    <code>d3dcompiler_46e.dll</code>
                  ]}
                />
              </span>,
              <span>
                <strong>
                  Re-verify your game through the Rockstar Launcher, Steam, or
                  Epic
                </strong>
              </span>
            ]}
          />
        </SolutionBox>
      </SolutionArea>
    </Question>
  )
}

export default D3d11Solution
