import { FunctionalComponent, h } from "preact"
import Page from "./Page"
import Hero from "../bulma/Hero"

interface Props {
  title: string
  description: string
}

const GuidePage: FunctionalComponent<Props> = props => {
  const { title, description, children } = props
  return (
    <Page
      pre={
        <Hero
          title={title}
          id='top'
          description={description}
          class='is-primary'
        />
      }
    >
      <article itemScope itemType='https://schema.org/Guide'>
        <meta itemProp='about' content={title} />
        <meta itemProp='name' content={description} />
        <nav
          class='mt-6 mb-6 is-centered has-arrow-separator breadcrumb'
          aria-label='breadcrumbs'
        >
          <ul>
            <li>
              <a href='/guides/'>Guides</a>
            </li>
            <li class='is-active'>
              <strong>
                <a href='#' aria-current='page'>
                  {title}
                </a>
              </strong>
            </li>
          </ul>
        </nav>
        <div itemProp='text'>{children}</div>
      </article>
    </Page>
  )
}

export default GuidePage
