import { FunctionalComponent, h } from "preact"
import styles from "./style.scss"

interface Props {
  ordered?: true
  items: JSX.Element[]
}

const SolutionSubList: FunctionalComponent<Props> = ({ ordered, items }) => {
  const headerClass = styles.solution_sub_item + " " + styles.solution_item
  const renderItem = (e: JSX.Element) => (
    <li>
      <h5 class={headerClass}>{e}</h5>
    </li>
  )
  return (
    <div class='mt-4'>
      {ordered ? (
        <ol>{items.map(renderItem)}</ol>
      ) : (
        <ul>{items.map(renderItem)}</ul>
      )}
    </div>
  )
}

export default SolutionSubList
