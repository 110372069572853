import { JSX, FunctionalComponent, h } from "preact"

type Props = { style?: JSX.CSSProperties }
const FiveMInstallDirectoryTable: FunctionalComponent<Props> = ({ style }) => {
  return (
    <table class='table mt-4' style={style}>
      <tbody>
        <tr>
          <td>
            <strong>Rockstar:</strong>
          </td>
          <td>
            <code>
              C:\Program Files (x86)\Rockstar Games\Grand Theft Auto V
            </code>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Steam:</strong>
          </td>
          <td>
            <code>
              C:\Program Files (x86)\Steam\commonapps\Grand Theft Auto V
            </code>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Epic:</strong>
          </td>
          <td>
            <code>C:\Program Files (x86)\Epic Games\GTA V\</code>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default FiveMInstallDirectoryTable
