import { FunctionalComponent, h } from "preact"
import { useEffect } from "preact/hooks"
import { CustomWindow } from "../../window"

declare const window: CustomWindow

interface Props {
  id: number
  currentPath: string
  adFormat?: string
  adLayout?: string
}

const AdSlot: FunctionalComponent<Props> = ({
  id,
  currentPath,
  adFormat,
  adLayout
}) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  }, [currentPath])

  return (
    <div class='ad pl-4 pr-4'>
      <ins
        class='adsbygoogle'
        style='display:block'
        data-ad-client='ca-pub-3740060524809311'
        data-ad-slot={id}
        data-ad-format={adFormat || "auto"}
        data-ad-layout={adLayout || undefined}
        data-full-width-responsive='true'
      />
    </div>
  )
}

export default AdSlot
