import { FunctionalComponent, h } from "preact"
import { Solution } from "../types"
import QuestionDescription from "../QuestionDescription"
import SolutionBox from "../answer/SolutionBox"
import Question from "../Question"
import DoNotBlameFiveM from "../DoNotBlameFiveM"
import ExternalLink from "../../../ExternalLink"
import Symptoms from "../answer/Symptoms"
import SolutionList from "../answer/SolutionList"
import Solutions from "../answer/Solutions"
import SolutionArea from "../answer/SolutionArea"
import SolutionSubList from "../answer/SolutionSubList"
import MidContentAd from "../../../ads/MidContentAd"

const FailedPackfileReadBulkFailedSolution: FunctionalComponent = () => {
  return (
    <div>
      <Question
        title='SSL_read error:1408F119 decryption failed or bad record MAC'
        id={Solution.PACKFILE_SSL_READ_ERROR}
        pre={<DoNotBlameFiveM ssl />}
      >
        <QuestionDescription>
          Your PC could not make a secure connection to the server
        </QuestionDescription>
        <SolutionArea>
          <SolutionBox>
            <Symptoms />
            <SolutionList
              items={[
                <code>
                  OpenSSL SSL_read error:1408F119SSL
                  rountines:ssl3_get_record:decryption failed or bad record mac
                  errno 0
                </code>,
                <code>
                  CURL error code 56 (Failure when receiving data from peer)
                </code>
              ]}
            />
          </SolutionBox>
          <SolutionBox>
            <Solutions />
            <SolutionList
              items={[
                <span>
                  Make sure your{" "}
                  <a href={`/solutions/${Solution.DISABLE_AV}`}>
                    anti-virus is not tampering with SSL/TLS connections.
                  </a>
                </span>,
                <span>Try using a VPN</span>
              ]}
            />
          </SolutionBox>
        </SolutionArea>
      </Question>

      <MidContentAd />

      <Question
        id={Solution.PACKFILE_PARTIAL_FILE}
        pre={<DoNotBlameFiveM network />}
        title='CURL error code 18 (Transferred a partial file)'
      >
        <QuestionDescription>
          Your resource download was interrupted
        </QuestionDescription>
        <SolutionArea>
          <SolutionBox>
            <Symptoms />
            <SolutionList
              items={[
                <code>CURL error code 18 (Transferred a partial file)</code>,
                <code>
                  Transfer closed with 123 bytes remaining to read - CURL error
                  code 18 (Transferred a partial file)
                </code>
              ]}
            />
          </SolutionBox>
          <SolutionBox>
            <Solutions />
            <SolutionList
              items={[
                <span>
                  <strong>Try reconnecting first</strong>, the server may have
                  had a hiccup.
                </span>,
                <span>
                  <strong>
                    Ensure your internet connection is working properly
                  </strong>
                </span>,
                <span>
                  <strong>
                    Test your{" "}
                    <ExternalLink href='https://packetlosstest.com/'>
                      network quality
                    </ExternalLink>
                    .
                  </strong>
                  <SolutionSubList
                    items={[
                      <span>
                        If you see any amount of loss, you should contact your
                        ISP.
                      </span>
                    ]}
                  />
                </span>
              ]}
            />
          </SolutionBox>
        </SolutionArea>
      </Question>

      <Question
        title='Failed to add entry to local storage (download corrupted?)'
        id={Solution.PACKFILE_FAILED_TO_SAVE}
      >
        <QuestionDescription>
          Your computer was unable to save the resource to the cache
        </QuestionDescription>
        <SolutionArea></SolutionArea>

        <div class='columns'>
          <SolutionBox>
            <Symptoms />
            <SolutionList
              items={[
                <code>
                  Couldn't load resource [name]: Failed to open packfile:
                  ReadBulk of header failed: Failed to fetch: Failed to add
                  entry to local storage (download corrupted?)
                </code>
              ]}
            />
          </SolutionBox>
          <SolutionBox>
            <Solutions />
            <SolutionList
              ordered
              items={[
                <span>
                  <strong>Ensure you have enough disk space</strong> on your C:/
                  drive
                  <ul class='mt-1'>
                    <li>
                      If your FiveM&reg; app data is on another drive, ensure
                      that the drive has enough space.
                    </li>
                  </ul>
                </span>,
                <span>
                  An anti-virus firewall may be interfering,{" "}
                  <a href={`/solutions/${Solution.DISABLE_AV}`}>
                    temporarily disable your anti-virus
                  </a>
                </span>,
                <span>
                  Your server cache may be corrupted,{" "}
                  <a href='#'>delete your server cache to fix this</a>
                </span>
              ]}
            />
          </SolutionBox>
        </div>
      </Question>
    </div>
  )
}

export default FailedPackfileReadBulkFailedSolution
