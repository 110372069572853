import { h, FunctionalComponent } from "preact"
import styles from "./style.css"

interface Props {
  class?: string
  id?: string
  title: string | JSX.Element
  description?: string | JSX.Element
}

const Hero: FunctionalComponent<Props> = props => {
  const { title, id, description, class: className, children } = props
  return (
    <header id={id} class={["hero", className].join(" ").trim()}>
      <div class='hero-body content'>
        <div class='container'>
          <h1 class='title is-size-1 mb-3'>{title}</h1>
          <h2 class={"subtitle is-size-3 mt-0 " + styles.subtitle}>
            {description}
          </h2>
        </div>
      </div>
      {children}
    </header>
  )
}

export default Hero
