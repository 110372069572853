import { FunctionalComponent, h } from "preact"
import clientCurlTimeout from "./img/curl-timeout.png"

import Question from "../Question"
import { Solution } from "../types"
import QuestionDescription from "../QuestionDescription"
import DoNotBlameFiveM from "../DoNotBlameFiveM"
import SolutionBox from "../answer/SolutionBox"
import SolutionArea from "../answer/SolutionArea"
import Symptoms from "../answer/Symptoms"
import Solutions from "../answer/Solutions"
import SolutionList from "../answer/SolutionList"
import SolutionSubList from "../answer/SolutionSubList"
import Figure from "../../Figure"

const CurlTimeoutSolution: FunctionalComponent = () => {
  return (
    <Question
      title='Timed out - CURL error code 28 (Timeout was reached)'
      id={Solution.CURL_TIMEOUT}
      pre={<DoNotBlameFiveM network />}
    >
      <QuestionDescription>
        You were unable to connect to the server
      </QuestionDescription>

      <SolutionArea>
        <SolutionBox>
          <Symptoms causes />
          <SolutionList
            items={[
              <span>
                <strong>If you're a player:</strong>
                <br />
                <SolutionSubList
                  items={[
                    <span>
                      The server is <strong>unavailable</strong>
                    </span>,
                    <span>
                      The server has <strong>changed IP addresses</strong>
                    </span>,
                    <span>
                      You are using an old{" "}
                      <code>
                        <strong>cfx.re</strong>
                      </code>{" "}
                      URL
                    </span>,
                    <span>
                      The IP or host you entered was an incorrect server address
                    </span>
                  ]}
                />
              </span>,
              <span>
                <strong>If you're a server owner:</strong>
                <br />
                <SolutionSubList
                  items={[
                    <span>Your server is unavailable</span>,
                    <span>Your server is not port forwarded properly</span>,
                    <span>
                      Your server is not allowed through your firewall
                    </span>
                  ]}
                />
              </span>
            ]}
          />
        </SolutionBox>
        <SolutionBox solution>
          <Solutions />
          <SolutionList
            solution
            items={[
              <span>
                <strong>For players:</strong>
                <br />
                <SolutionSubList
                  items={[
                    <span>
                      Try clearing your Windows DNS:
                      <br />
                      <SolutionSubList
                        items={[
                          <span>
                            Press <kbd>WIN</kbd>+<kbd>X</kbd>, then press{" "}
                            <kbd>I</kbd>
                          </span>,
                          <span>
                            Enter <code>ipconfig /flushdns</code>
                          </span>,
                          <span>Retry connecting to the FiveM&reg; server</span>
                        ]}
                      />
                    </span>,
                    <span>
                      If that doesn't work, you should contact the server owners
                      to let them know you're unable to connect.
                    </span>
                  ]}
                />
              </span>,
              <span>
                <strong>For server owners:</strong>
                <br />
                <SolutionSubList
                  items={[
                    <span>Make sure that FXServer is running</span>,
                    <span>
                      Make sure FXServer is not blocked by a firewall or router
                    </span>,
                    <span>Make sure your server has internet access</span>,
                    <span>
                      Contact your server host if your networking issues
                      continue
                    </span>
                  ]}
                />
              </span>
            ]}
          />
        </SolutionBox>
      </SolutionArea>

      <br />
      <Figure label='An example of the curl timeout error'>
        <img src={clientCurlTimeout} alt='An example of a CURL timeout' />
      </Figure>
    </Question>
  )
}

export default CurlTimeoutSolution
