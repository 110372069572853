import { FunctionalComponent, h } from "preact"
import SolutionPage from "../../components/page/SolutionPage"
import DisableAVSolution from "../../components/page/solution/common-errors/DisableAVSolution"

const DisablingAVSolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title='How to Temporarily Disable Your Anti-Virus'
      description='Anti-virus applications may prevent FiveM&reg; from running'
    >
      <DisableAVSolution />
    </SolutionPage>
  )
}

export default DisablingAVSolutionPage
