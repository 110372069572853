import Question from "../Question"
import { h, FunctionalComponent } from "preact"
import QuestionDescription from "../QuestionDescription"
import { Solution } from "../types"
import SolutionArea from "../answer/SolutionArea"
import SolutionBox from "../answer/SolutionBox"
import Solutions from "../answer/Solutions"
import Symptoms from "../answer/Symptoms"
import SolutionList from "../answer/SolutionList"
import SolutionSubList from "../answer/SolutionSubList"
import DoNotBlameFiveM from "../DoNotBlameFiveM"

const InvalidFixupSolution: FunctionalComponent = () => {
  return (
    <Question
      title='Invalid fixup, address is neither virtual nor physical'
      id={Solution.INVALID_FIXUP}
      pre={<DoNotBlameFiveM server />}
    >
      <QuestionDescription>A server resource is invalid</QuestionDescription>

      <SolutionArea>
        <SolutionBox>
          <Symptoms />
          <SolutionList
            items={[
              <code>
                Invalid fixup, address is neither virtual nor physical (in
                [name].yft)
              </code>,
              <code>
                Invalid fixup, address is neither virtual nor physical (in
                [name].ymap)
              </code>
            ]}
          />
        </SolutionBox>
        <SolutionBox>
          <Solutions />
          <SolutionList
            items={[
              <span>
                <strong>
                  Ask the server owner to remove the invalid resource mentioned
                  in the error:
                </strong>
                <br />
                <SolutionSubList
                  items={[
                    <span>
                      Providing a screenshot will help them find the issue.
                    </span>
                  ]}
                />
              </span>
            ]}
          />
        </SolutionBox>
      </SolutionArea>
    </Question>
  )
}

export default InvalidFixupSolution
