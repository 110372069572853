import { FunctionalComponent, h } from "preact"

interface Props {
  name: string
  href?: string
}

const NavbarDropdown: FunctionalComponent<Props> = ({
  name,
  children,
  href
}) => {
  return (
    <div class='navbar-item has-dropdown is-hoverable'>
      <a class='navbar-link' href={href}>
        {name}
      </a>
      <div class='navbar-dropdown'>{children}</div>
    </div>
  )
}

export default NavbarDropdown
