import SolutionPage from "../../components/page/SolutionPage"
import MissingDLLSolution from "../../components/page/solution/common-errors/MissingDLLSolution"
import { FunctionalComponent, h } from "preact"

const MissingDLLSolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title='How to Fix Missing FiveM&reg; DLLs'
      description='Learn how to temporarily disable your anti-virus so FiveM&reg; can run'
    >
      <MissingDLLSolution />
    </SolutionPage>
  )
}

export default MissingDLLSolutionPage
