import { FunctionalComponent, h } from "preact"
import styles from "./style.scss"

interface Props {
  causes?: true
  title?: string
}

const Symptoms: FunctionalComponent<Props> = ({ title, causes }) => {
  return (
    <h3 class={"is-size-4 has-text-danger " + styles.symptoms}>
      {title || (causes ? "Causes:" : "Symptoms:")}
    </h3>
  )
}

export default Symptoms
