import { FunctionalComponent, h } from "preact"
import SolutionPage from "../../components/page/SolutionPage"
import Question from "../../components/page/solution/Question"
import DoNotBlameFiveM from "../../components/page/solution/DoNotBlameFiveM"
import { Solution } from "../../components/page/solution/types"
import QuestionDescription from "../../components/page/solution/QuestionDescription"
import LongAnswer from "../../components/page/solution/LongAnswer"
import SolutionArea from "../../components/page/solution/answer/SolutionArea"
import SolutionBox from "../../components/page/solution/answer/SolutionBox"
import Symptoms from "../../components/page/solution/answer/Symptoms"
import Solutions from "../../components/page/solution/answer/Solutions"
import SolutionList from "../../components/page/solution/answer/SolutionList"
import { Guide } from "../../components/page/guide/types"

const OutOfDateGameSolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title="How to fix 'DLC Files are missing or corrupted'"
      description='How to fix the "DLC files are missing (or corrupted)" error'
    >
      <Question
        id={Solution.OUT_OF_DATE}
        pre={<DoNotBlameFiveM />}
        title='DLC files are missing (or corrupted)'
      >
        <QuestionDescription>
          You receive the error "DLC files are missing (or corrupted)"
        </QuestionDescription>
        <LongAnswer>
          This error message means that your copy of GTA V&reg; is either out of
          date, or some of its files are corrupted.
        </LongAnswer>

        <SolutionArea>
          <SolutionBox>
            <Symptoms />
            <SolutionList
              items={[
                <span>
                  DLC files are missing (or corrupted) in your game
                  installation. Please update or verify the game using Steam,
                  Epic Games Launcher or Rockstar Games Launcher and try again.
                </span>
              ]}
            />
          </SolutionBox>
          <SolutionBox>
            <Solutions />
            <SolutionList
              items={[
                <span>
                  Ensure that your game is update to date using the platform you
                  purchased it with (Rockstar, Steam, Epic Games).
                </span>,
                <span>
                  If that does not fix the issue, read our guide on{" "}
                  <a href={`/guides/${Guide.HOW_TO_REPAIR_GTAV}/`}>
                    how to fix your GTA V files
                  </a>
                  .
                </span>
              ]}
            />
          </SolutionBox>
        </SolutionArea>
      </Question>
    </SolutionPage>
  )
}

export default OutOfDateGameSolutionPage
