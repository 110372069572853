import { FunctionalComponent, h } from "preact"

type Props = { server?: true; ssl?: true; network?: true }

const DoNotBlameFiveM: FunctionalComponent<Props> = ({
  server,
  ssl,
  network
}) => {
  let className = "is-warning"
  let notification: JSX.Element = (
    <span>
      <strong>This is not an issue with FiveM&reg;</strong>, you will have to
      resolve this issue yourself.
    </span>
  )

  if (server) {
    notification = (
      <span>
        <strong>This is an issue with a specific server.</strong> Please contact
        the server host to fix this issue.
      </span>
    )
  } else if (network) {
    className = "is-danger"
    notification = (
      <span>
        <strong>This is an issue with your network.</strong> The issue could be
        either your network, the server's network, or a third-party service
        being unavailable.{" "}
        <strong>This is not an issue with FiveM&reg;.</strong>
      </span>
    )
  } else if (ssl) {
    className = "is-danger"
    notification = (
      <span>
        <strong>A secure connection could not be established.</strong> The issue
        could be either your network, an anti-virus firewall, or government
        firewall intercepting traffic.{" "}
        <strong>This is not an issue with FiveM&reg;.</strong>
      </span>
    )
  }
  return (
    <div class={"notification " + className}>
      <h3>{notification}</h3>
    </div>
  )
}

export default DoNotBlameFiveM
