import { FunctionalComponent, h } from "preact"
import styles from "./style.scss"

const Solutions: FunctionalComponent<{ title?: string }> = ({ title }) => (
  <h3 class={"has-text-success is-size-4 " + styles.solutions}>
    {title || "Solutions:"}
  </h3>
)

export default Solutions
