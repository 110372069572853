import { FunctionalComponent, h } from "preact"
import GuidePage from "../../../components/page/GuidePage"
import GuideHeader from "../../../components/page/guide/GuideHeader"
import GuideContentSection from "../../../components/page/guide/GuideContentSection"
import MidContentAd from "../../../components/ads/MidContentAd"
import GuideContent from "../../../components/page/guide/GuideContent"
import SolutionBox from "../../../components/page/solution/answer/SolutionBox"
import Solutions from "../../../components/page/solution/answer/Solutions"
import SolutionList from "../../../components/page/solution/answer/SolutionList"
import SocialClubImage from "./social-club.png"
import SteamImage from "./steam.png"
import EpicLauncherImage from "./epic.png"
import EpicSettingsImage from "./epic-2.png"

const HowToRepairGTAGuide: FunctionalComponent = () => {
  return (
    <GuidePage
      title='How To Verify / Repair GTA V&reg;'
      description='This guide shows you how to remove any mods from FiveM&reg; / GTA V&reg;'
    >
      <GuideContentSection>
        <GuideHeader title='Repairing Your Grand Theft Auto V&reg; Game'>
          <p>
            <strong>
              This guide provides simple instructions for verifying and
              repairing your copy of Grand Theft Auto V&reg;.
            </strong>{" "}
            Whether you purchased it through Rockstar, Epic, or Steam, this
            guide will cover it.
          </p>
        </GuideHeader>
        <hr />

        <div class='box has-background-dark has-text-white has-text-centered'>
          <h3 class='has-text-white is-size-2 mb-2'>Select Your Launcher</h3>
          <p class='is-size-5'>
            Quickly jump to the instructions for your launcher
          </p>
          <div class='buttons is-centered'>
            <a href='#rockstar-launcher' class='button is-large is-info'>
              Rockstar Social Club
            </a>
            <a href='#epic-launcher' class='button is-large is-info'>
              Epic Games
            </a>
            <a href='#steam-launcher' class='button is-large is-info'>
              Steam
            </a>
          </div>
        </div>
        <hr />

        <GuideContent title='Why This Happens' id='why'>
          <p>
            You may need to validate your files for the following reasons:
            <strong>
              <ul>
                <li>You installed or made modifications to your game files</li>
                <li>Your game is out of date</li>
                <li>An update was not applied properly</li>
                <li>Your files have become corrupted</li>
              </ul>
            </strong>
          </p>
          <p>
            Validating your files will re-download the invalid files from the
            site where you purchased GTA V&reg; from. You should be aware that
            if many files are corrupted or have been modified, the download may
            be large (1-10+ GB).{" "}
            <strong>
              Any modifications you may have will be <em>removed</em>.
            </strong>
          </p>
        </GuideContent>

        <hr />
        <MidContentAd />
        <hr />

        <GuideContent
          title='Repairing with Rockstar Social Club Launcher'
          id='rockstar-launcher'
        >
          <div class='columns is-multiline'>
            <div class='column is-6'>
              <p>
                In order to validate your game files, you will need to use the{" "}
                <strong>
                  <dfn>Command Prompt</dfn>
                </strong>{" "}
                on your PC. First, you will need to{" "}
                <strong>
                  <dfn>cd</dfn>
                </strong>{" "}
                <em>(change directories)</em> into the directory that you have
                installed the GTA V&reg; game in.
              </p>
              <p>
                The <strong>default location</strong> that GTA V is installed
                to:
                <pre class='mt-4'>
                  C:\Program Files\Rockstar Games\Grand Theft Auto V\
                </pre>
              </p>
              <img
                src={SocialClubImage}
                alt='The Rockstar Social Club Launcher'
              />
            </div>
            <div class='column is-6'>
              <SolutionBox widthClass=' '>
                <Solutions title='Solution:' />
                <SolutionList
                  items={[
                    <span>
                      Press the <kbd>⊞ Win</kbd> key on your keyboard to bring
                      up the start menu, and then type in{" "}
                      <strong>Command Prompt</strong>, and press{" "}
                      <kbd>↵ Enter</kbd> to start it.
                    </span>,
                    <span>
                      Once the Command Prompt is open, change directories to
                      your game files:
                      <br />
                      <br />
                      <code>
                        cd C:\Program Files\Rockstar Games\Grand Theft Auto V
                      </code>
                      <br />
                      <br />
                      If your game is installed in a different folder or drive,
                      you will need to use that folder instead:
                      <br />
                      <br />
                      <code>cd E:\Your\Path\To\GTA</code>
                    </span>,
                    <span>
                      Type the following command in to verify your game files:
                      <br />
                      <br />
                      <code>GTAVLauncher.exe -verify</code>
                    </span>,
                    <span>
                      The Rockstar launcher will now verify your game files.
                    </span>
                  ]}
                />
              </SolutionBox>
            </div>
          </div>
        </GuideContent>
        <hr />
        <GuideContent
          title='Repairing with Epic Games Launcher'
          id='epic-launcher'
        >
          <div class='columns is-multline'>
            <div class='column is-6'>
              <p>
                It is easy to verify your GTA V game files with the Epic
                Launcher.
                <br />
                First, you will need to{" "}
                <strong>open the Epic Games Launcher</strong>.
              </p>
              <div class='has-text-centered'>
                <img
                  src={EpicLauncherImage}
                  alt='The validation process in the Epic Games launcher'
                  width={450}
                />
              </div>
            </div>
            <div class='column is-6'>
              <SolutionBox widthClass=' '>
                <Solutions title='Solution:' />
                <SolutionList
                  items={[
                    <span>Open the Epic Games Launcher</span>,
                    <span>
                      Select the <strong>Library</strong> tab
                    </span>,
                    <span>
                      Find <strong>Grand Theft Auto V</strong> in your games
                      list
                    </span>,
                    <span>
                      Click on the button with three dots next to the game name:
                      <br />
                      <br />
                      <img
                        src={EpicSettingsImage}
                        alt='The ellipsis button to click on to verify your game'
                      />
                    </span>,
                    <span>
                      Then, click on <strong>Verify</strong>
                    </span>,
                    <span>
                      The Epic launcher will begin verifying your game files
                    </span>
                  ]}
                />
              </SolutionBox>
            </div>
          </div>
        </GuideContent>
        <hr />
        <GuideContent title='Repairing with Steam Launcher' id='steam-launcher'>
          <div class='columns is-multiline'>
            <div class='column is-6'>
              <p>
                Repairing your game files with the{" "}
                <strong>Steam Launcher</strong> is very simple. First, make sure
                your Steam client is open, then go to the{" "}
                <strong>Library</strong> tab.
              </p>
              <img src={SteamImage} alt='' />
            </div>
            <div class='column is-6'>
              <SolutionBox widthClass=' '>
                <Solutions title='Solution:' />
                <SolutionList
                  items={[
                    <span>
                      Select the <strong>Library</strong> tab in the Steam
                      client
                    </span>,
                    <span>
                      Find <strong>Grand Theft Auto V</strong> in your games
                      list
                    </span>,
                    <span>
                      Right Click the item in the list and click on{" "}
                      <strong>Properties</strong>
                    </span>,
                    <span>
                      In the window that pops up, click on{" "}
                      <strong>Local Files</strong>
                    </span>,
                    <span>
                      Finally, click on{" "}
                      <strong>Verify integrity of game files...</strong>
                    </span>,
                    <span>
                      Steam will then verify your game files, and immediately
                      start downloading any corrupted or modified files.
                    </span>
                  ]}
                />
              </SolutionBox>
            </div>
          </div>
        </GuideContent>
      </GuideContentSection>
    </GuidePage>
  )
}

export default HowToRepairGTAGuide
