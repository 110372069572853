import Question from "../Question"
import QuestionDescription from "../QuestionDescription"
import DoNotBlameFiveM from "../DoNotBlameFiveM"
import { FunctionalComponent, h } from "preact"
import { Solution } from "../types"
import SolutionBox from "../answer/SolutionBox"
import LongAnswer from "../LongAnswer"
import SolutionArea from "../answer/SolutionArea"
import Symptoms from "../answer/Symptoms"
import Solutions from "../answer/Solutions"
import SolutionList from "../answer/SolutionList"
import SolutionSubList from "../answer/SolutionSubList"

const MissingDLLSolution: FunctionalComponent = () => {
  return (
    <Question
      title='Could not load CitizenGame.dll or [name].dll'
      id={Solution.MISSING_DLLS}
      pre={<DoNotBlameFiveM />}
    >
      <QuestionDescription>
        An anti-virus has removed or is blocking FiveM&reg; game files, and
        cannot launch properly
      </QuestionDescription>

      <LongAnswer>
        This error happens when an anti-virus client has removed critical FiveM
        game files, or they have become corrupted.
        <br />
        <strong>The FiveM&reg; client is self-repairing</strong>, but you must
        stop any anti-virus applications from removing your game files.
      </LongAnswer>

      <SolutionArea>
        <SolutionBox>
          <Symptoms />
          <SolutionList
            items={[
              <span>
                <code>Could not load CitizenGame.dll</code>
              </span>,
              <span>
                <code>Could not load [name].dll</code>
              </span>,
              <span>
                <code>Error loading component [name].dll</code>
              </span>
            ]}
          />
        </SolutionBox>

        <SolutionBox>
          <Solutions />
          <SolutionList
            items={[
              <span>
                <a href={`/solutions/${Solution.DISABLE_AV}`}>
                  Temporarily disable your anti-virus software
                </a>
              </span>,
              <span>
                <strong>Repair your FiveM&reg; Install</strong>
                <SolutionSubList
                  ordered
                  items={[
                    <span>
                      Ensure all FiveM&reg; processes are closed. You can verify
                      this with the Windows&reg; <strong>Task Manager</strong>
                    </span>,
                    <span>
                      Delete the{" "}
                      <code>
                        %localappdata%/FiveM/FiveM Application Data/caches.xml
                      </code>{" "}
                      file
                    </span>,
                    <span>Try to launch the FiveM&reg; client again</span>
                  ]}
                />
              </span>
            ]}
          />
        </SolutionBox>
      </SolutionArea>
    </Question>
  )
}

export default MissingDLLSolution
