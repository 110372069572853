import { FunctionalComponent, h } from "preact"
import clientSettingsReleaseChannelImage from "./client-settings-release-channel.png"
import Figure from "../../../page/Figure"

const ClientSettingsReleaseChannelImage: FunctionalComponent = () => {
  const alt =
    'The release channel option is located under "Game" on the settings page.'
  return (
    <Figure label={alt}>
      <img src={clientSettingsReleaseChannelImage} width={500} alt={alt} />
    </Figure>
  )
}

export default ClientSettingsReleaseChannelImage
