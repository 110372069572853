import Question from "../Question"
import QuestionDescription from "../QuestionDescription"
import clientStreamInflateError from "./img/streaming-inflate-error.png"
import { FunctionalComponent, h } from "preact"
import { Solution } from "../types"
import SolutionBox from "../answer/SolutionBox"
import SolutionArea from "../answer/SolutionArea"
import Solutions from "../answer/Solutions"
import Symptoms from "../answer/Symptoms"
import SolutionList from "../answer/SolutionList"
import SolutionSubList from "../answer/SolutionSubList"
import Figure from "../../Figure"

const InflateFailedSolution: FunctionalComponent = () => {
  return (
    <Question
      title='Failed to call inflate() for streaming file [name].ytd'
      id={Solution.INFLATE_FAILED}
    >
      <QuestionDescription>
        The named server resource file is invalid or corrupted.
      </QuestionDescription>

      <SolutionArea>
        <SolutionBox>
          <Symptoms />
          <SolutionList
            items={[
              <span>
                <code>Failed to call inflate() for streaming file ...</code>
              </span>,
              <span>
                <code>gta-streaming-five.dll+6F5CB</code>
              </span>
            ]}
          />

          <Figure label='An example of a broken texture resource'>
            <img
              src={clientStreamInflateError}
              width={400}
              alt='An example of an inflate() error'
            />
          </Figure>
        </SolutionBox>

        <SolutionBox>
          <Solutions />
          <SolutionList
            items={[
              <span>
                <strong>For players:</strong>
                <SolutionSubList
                  items={[
                    <span>
                      Ask the server owner to remove or fix the resource file
                      mentioned in the crash.
                    </span>,
                    <span>
                      Providing a screenshot of the crash to them will help fix
                      the issue.
                    </span>
                  ]}
                />
              </span>,
              <span class='pt-2'>
                <strong>For server owners:</strong>
                <SolutionSubList
                  items={[
                    <span>
                      Try replacing the affected asset with its original, and
                      restart the server
                    </span>,
                    <span>Remove the asset file, and restart the server</span>
                  ]}
                />
              </span>
            ]}
          />
        </SolutionBox>
      </SolutionArea>
    </Question>
  )
}

export default InflateFailedSolution
