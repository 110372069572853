import { FunctionalComponent, h } from "preact"

interface Props {
  widthClass?: string
  solution?: boolean
}

const SolutionBox: FunctionalComponent<Props> = ({
  children,
  widthClass,
  solution
}) => {
  const answerScopeProps = {
    itemScope: true,
    itemProp: "suggestedAnswer acceptedAnswer",
    itemType: "https://schema.org/Answer"
  }

  return (
    <div
      class={"column is-flex solution-box " + (widthClass || "is-half")}
      {...(solution ? answerScopeProps : {})}
    >
      <div class='box' style={{ flexGrow: 1 }}>
        {children}
      </div>
    </div>
  )
}

export default SolutionBox
