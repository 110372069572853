import { FunctionalComponent, h } from "preact"
import { Solution } from "./types"

interface Props {
  title: string
  id: Solution
  pre?: JSX.Element[] | JSX.Element
}

const Question: FunctionalComponent<Props> = ({ title, id, children, pre }) => {
  return (
    <div class='question p-4 m-0' id={id}>
      <hr />
      {pre && pre}
      <div itemScope itemType='https://schema.org/Question'>
        <div class='content'>
          <h2 class='is-size-2'>
            <span itemProp='headline'>{title}</span>&nbsp;
            <sup>
              <a
                class='has-text-grey'
                style={{ textDecoration: "none" }}
                href={`#${id}`}
              >
                #
              </a>
            </sup>
          </h2>
        </div>
        {children}
      </div>
      <hr />
    </div>
  )
}

export default Question
