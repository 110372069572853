import { FunctionalComponent, h } from "preact"
import SolutionPage from "../../components/page/SolutionPage"
import InvalidFixupSolution from "../../components/page/solution/common-errors/InvalidFixupSolution"

const InvalidFixupSolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title={`How to Fix FiveM® "Invalid fixup" Error`}
      description='The server streamed an invalid resource'
    >
      <InvalidFixupSolution />
    </SolutionPage>
  )
}

export default InvalidFixupSolutionPage
