/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FunctionalComponent, h } from "preact"
import { Link } from "preact-router"
import snailyImage from "./snaily.png"

import NavbarDropdown from "./NavbarDropdown"
import { Solution } from "../page/solution/types"
import { Guide } from "../page/guide/types"

const Navbar: FunctionalComponent = () => {
  return (
    <nav
      class='navbar is-primary'
      role='navigation'
      aria-label='navigation bar'
    >
      <div class='container'>
        <div class='navbar-brand'>
          <Link class='navbar-item' href='/'>
            <img
              src={snailyImage}
              class='mr-3'
              width={28}
              height={28}
              alt='Snaily'
            />
            <strong class='is-size-4'>
              <span class='pt-2'>FiveM.guide</span>
            </strong>
          </Link>

          <a
            role='button'
            class='navbar-burger'
            aria-label='menu'
            aria-expanded='false'
            onClick={function () {
              const burger = document.querySelector(".navbar-burger")!
              const navbar = document.getElementById("navbar")!
              burger.classList.toggle("is-active")
              navbar.classList.toggle("is-active")
            }}
          >
            <span aria-hidden='true' />
            <span aria-hidden='true' />
            <span aria-hidden='true' />
          </a>
        </div>

        <div class='navbar-menu' id='navbar'>
          <div class='navbar-start'>
            <Link class='navbar-item' activeClassName='is-primary' href='/'>
              Home
            </Link>
            <NavbarDropdown name='Solutions'>
              <a
                href={`/solutions/${Solution.CURL_TIMEOUT}/`}
                class='navbar-item'
              >
                Curl Timeout
              </a>
              <a
                href={`/solutions/${Solution.ROS_MTL_FAILED}/`}
                class='navbar-item'
              >
                ROS/MDT Timeout
              </a>
              <a
                href={`/solutions/${Solution.STEAM_NOT_FOUND}/`}
                class='navbar-item'
              >
                Unable to find SteamID
              </a>
              <a
                href={`/solutions/${Solution.CFX_BANNED}/`}
                className='navbar-item'
              >
                Banned from the Cfx.re network
              </a>
              <div class='navbar-divider' />
              <a
                href={`/solutions/${Solution.PACKFILE_SSL_READ_ERROR}/`}
                class='navbar-item'
              >
                Packfile Error
              </a>
              <a
                href={`/solutions/${Solution.INVALID_FIXUP}/`}
                class='navbar-item'
              >
                Invalid Fixup Error
              </a>
              <a
                href={`/solutions/${Solution.MISSING_DLLS}/`}
                class='navbar-item'
              >
                Missing DLLs
              </a>
              <a href={`/solutions/${Solution.D3D11_DLL}/`} class='navbar-item'>
                d3d11.dll Crash
              </a>
              <a
                href={`/solutions/${Solution.INFLATE_FAILED}/`}
                class='navbar-item'
              >
                inflate() Failed
              </a>
              <a
                href={`/solutions/${Solution.PACKFILE_SSL_READ_ERROR}/`}
                class='navbar-item'
              >
                Failed to open packfile
              </a>
              <a
                href={`/solutions/${Solution.OUT_OF_DATE}/`}
                className='navbar-item'
              >
                DLC files are missing
              </a>
            </NavbarDropdown>
            <NavbarDropdown name='Guides' href='/guides/'>
              <a
                href={`/guides/${Guide.WHAT_IS_FIVEM_GUIDE}/`}
                className='navbar-item'
              >
                What Is FiveM&reg;?
              </a>
              <a
                href={`/guides/${Guide.COMMON_FIVEM_ISSUES_GUIDE}/`}
                class='navbar-item'
              >
                Common FiveM&trade; Issues
              </a>
              <a
                href={`/guides/${Guide.INTRO_TO_GTA_RP_GUIDE}/`}
                class='navbar-item'
              >
                Intro To FiveM Roleplay (RP)
              </a>
              <a
                href={`/guides/${Guide.HOW_TO_REPAIR_GTAV}/`}
                class='navbar-item'
              >
                How To Repair GTA V&reg;
              </a>
            </NavbarDropdown>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
