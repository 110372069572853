import AdSlot from "./AdSlot"
import { h, FunctionalComponent } from "preact"

const StandardResponsivePageAd: FunctionalComponent = () => {
  let currentPath = "/"
  if (typeof window !== "undefined") {
    currentPath = window.location.pathname
  }
  return <AdSlot currentPath={currentPath} id={9822389556} />
}

export default StandardResponsivePageAd
