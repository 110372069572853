import { h, FunctionalComponent } from "preact"
import Question from "../Question"
import QuestionDescription from "../QuestionDescription"
import { Solution } from "../types"

const RPFSolution: FunctionalComponent = () => {
  return (
    <Question title='Failed to read rage::fiPackfile' id={Solution.INVALID_RPF}>
      <QuestionDescription>
        A core game resource file is corrupted or missing
      </QuestionDescription>
    </Question>
  )
}

export default RPFSolution
