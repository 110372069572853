import { FunctionalComponent, h } from "preact"
import SolutionPage from "../../components/page/SolutionPage"
import Question from "../../components/page/solution/Question"
import { Solution } from "../../components/page/solution/types"
import DoNotBlameFiveM from "../../components/page/solution/DoNotBlameFiveM"
import QuestionDescription from "../../components/page/solution/QuestionDescription"
import LongAnswer from "../../components/page/solution/LongAnswer"
import SolutionArea from "../../components/page/solution/answer/SolutionArea"
import SolutionBox from "../../components/page/solution/answer/SolutionBox"
import Symptoms from "../../components/page/solution/answer/Symptoms"
import SolutionList from "../../components/page/solution/answer/SolutionList"
import Solutions from "../../components/page/solution/answer/Solutions"

const CfxBannedSolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title='Banned from the Cfx.re Network'
      description='What to do after you get banned from FiveM'
    >
      <Question
        title='You have been banned from the Cfx.re network'
        id={Solution.CFX_BANNED}
        pre={<DoNotBlameFiveM />}
      >
        <QuestionDescription>
          You have been banned from the Cfx.re network
        </QuestionDescription>
        <LongAnswer>
          You are globally banned from the Cfx.re network because you were
          detected injecting into the FiveM application. We highly recommend
          reading Cfx.re's{" "}
          <a
            href='https://docs.fivem.net/docs/support/ban-faq/'
            rel='noopener'
            target='_blank'
          >
            official guide on server bans
          </a>
          .
        </LongAnswer>

        <SolutionArea>
          <SolutionBox>
            <Symptoms />
            <SolutionList
              items={[
                <span>You have been banned from the Cfx.re network.</span>,
                <span>
                  A component of your computer is preventing you from being able
                  to play. If you were evading, wait out your original ban
                </span>
              ]}
            />
          </SolutionBox>
          <SolutionBox>
            <Solutions />
            <SolutionList
              items={[
                <span>
                  You cannot appeal or evade your Cfx.re ban, attempting to
                  evade your ban will extend it
                </span>,
                <span>
                  Wait out your ban and remove any cheat software you may have
                </span>,
                <span>
                  View the{" "}
                  <a
                    href='https://docs.fivem.net/docs/support/ban-faq/'
                    rel='noopener'
                    target='_blank'
                  >
                    official Cfx.re guide on server bans
                  </a>
                </span>
              ]}
            />
          </SolutionBox>
        </SolutionArea>
      </Question>
    </SolutionPage>
  )
}

export default CfxBannedSolutionPage
