import SolutionPage from "../../components/page/SolutionPage"
import ROSSolution from "../../components/page/solution/common-errors/ROSSolution"
import { h, FunctionalComponent } from "preact"

const ROSTimeoutSolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title='How To Solve ROS/MDT Timeout Errors'
      description='Your computer was unable to connect to Rockstar Online Services'
    >
      <ROSSolution />
    </SolutionPage>
  )
}

export default ROSTimeoutSolutionPage
