import ClientSettingsReleaseChannelImage from "../../../images/client/settings/ClientSettingsReleaseChannelImage"
import Question from "../Question"
import { FunctionalComponent, h } from "preact"
import { Solution } from "../types"
import QuestionDescription from "../QuestionDescription"
import SolutionBox from "../answer/SolutionBox"
import LongAnswer from "../LongAnswer"
import SolutionArea from "../answer/SolutionArea"
import Solutions from "../answer/Solutions"
import SolutionList from "../answer/SolutionList"

const CanarySolution: FunctionalComponent = () => {
  return (
    <Question
      title='Ensure You Are Not Using Canary'
      id={Solution.DISABLE_CANARY}
      pre={
        <div class='notification is-info'>
          <strong>Unless you are using Canary for development</strong>, then you
          will want to switch back to the <strong>Release</strong> channel.
        </div>
      }
    >
      <QuestionDescription>
        Using the <strong>canary version</strong> for regular play can cause
        unexpected crashes on servers.
      </QuestionDescription>
      <LongAnswer>
        If you are experiencing irregular, unexplained crashes without any
        modification to your client files, or server files &mdash; then you may
        have the Canary version selected.
      </LongAnswer>

      <SolutionArea>
        <SolutionBox>
          <Solutions title='Method 1: The FiveM&reg; client works' />
          <SolutionList
            ordered
            items={[
              <span>
                <strong>Step 1</strong>: Open the FiveM&reg; client, and click
                on the gear icon in the top right.
              </span>,
              <span>
                <strong>Step 2</strong>: Make sure that the{" "}
                <strong>Release</strong> channel is selected.
                <ClientSettingsReleaseChannelImage />
              </span>
            ]}
          />
        </SolutionBox>

        <SolutionBox>
          <Solutions title='Method 2: FiveM&reg; will not launch' />
          <SolutionList
            ordered
            items={[
              <span>
                <strong>Close the FiveM&reg; client</strong>
              </span>,
              <span>
                <strong>
                  Open the <code>CitizenFX.ini</code> file.
                </strong>
                &nbsp; You can find it in
                <br /> <code>%localappdata%/FiveM/FiveM Application Data/</code>
              </span>,
              <span>
                If you see a line that contains{" "}
                <code>UpdateChannel=canary</code>, <strong>remove it</strong>.
              </span>,
              <span>
                <strong>Start the FiveM&reg; client again</strong>
              </span>
            ]}
          />
        </SolutionBox>
      </SolutionArea>
    </Question>
  )
}

export default CanarySolution
