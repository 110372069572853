import { FunctionalComponent, h } from "preact"
import GuidePage from "../../../components/page/GuidePage"
import GuideContentSection from "../../../components/page/guide/GuideContentSection"
import GuideHeader from "../../../components/page/guide/GuideHeader"
import GuideContent from "../../../components/page/guide/GuideContent"
import MidContentAd from "../../../components/ads/MidContentAd"
import { Guide } from "../../../components/page/guide/types"
import { Solution } from "../../../components/page/solution/types"

const WhatIsFiveMGuide: FunctionalComponent = () => {
  return (
    <GuidePage
      title='What is FiveM&reg; Exactly?'
      description='Everything you need to know about the GTA V&reg; mod, FiveM&reg;'
    >
      <GuideContentSection>
        <GuideHeader title='So, What is FiveM&reg;?'>
          <a
            href='https://fivem.net/'
            target='_blank'
            rel='noreferrer noopener'
          >
            <strong>FiveM&reg;</strong> is an open-source mod for the the game{" "}
            <strong>Grand Theft Auto V&reg;</strong>.
          </a>
        </GuideHeader>
        <hr />
        <GuideContent id='what-is-fivem-for' title='What is FiveM&reg;'>
          <div class='columns'>
            <div class='column is-two-thirds'>
              <h3>For Players:</h3>
              <h4>Easy to Use</h4>
              <p>
                When you connect to a server, FiveM automatically downloads and
                loads any mods, without requiring you to install them manually.
                This means all you have to do is find a server you like and
                connect!
              </p>

              <h4>Unique Servers</h4>
              <p>
                These custom servers allow players to experience far more than
                is available on GTA Online. From roleplay servers to individual
                gamemodes, to FPS shooters, the varieties are endless.
              </p>
              <p>
                This means that the answer to the question of "What is FiveM?"
                is whatever the community creates! There are new and unique
                servers every day. To find a list of servers looking for new
                players, you can look in the{" "}
                <a
                  href='https://servers.fivem.net/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  FiveM Server List
                </a>
              </p>
            </div>
            <div className='column is-one-third'>
              <h3>For Developers:</h3>
              <p>
                FiveM&reg; allows server owners to download and use scripts on a
                server they host. FiveM allows them access to the{" "}
                <strong>RAGE Engine</strong>, Rockstar's game engine used
                throughout many of their games. You can write scripts in{" "}
                <a
                  href='https://docs.fivem.net/docs/scripting-manual/introduction/creating-your-first-script/'
                  target='_blank'
                  rel='noopener'
                >
                  Lua
                </a>
                ,{" "}
                <a
                  href='https://docs.fivem.net/docs/scripting-manual/introduction/creating-your-first-script-csharp/'
                  target='_blank'
                  rel='noopener'
                >
                  C#
                </a>
                , or{" "}
                <a
                  href='https://docs.fivem.net/docs/scripting-manual/introduction/creating-your-first-script-javascript/'
                  target='_blank'
                  rel='noopener'
                >
                  JavaScript
                </a>{" "}
                and create anything you want!
              </p>
            </div>
          </div>
        </GuideContent>

        <hr />
        <MidContentAd />
        <hr />
        <GuideContent
          id='how-to-download-fivem'
          title='How To Download FiveM&reg;'
        >
          <div class='columns'>
            <div class='column is-two-thirds'>
              <h3>For Windows</h3>

              <p>
                You will need to have a legitimate copy of the Grand Theft Auto
                V&reg; game installed already before being able to use FiveM.
              </p>
              <p>
                The FiveM installation takes around 1-3GB of data to download,
                so you may want to make some tea!
              </p>

              <div className='box has-text-centered is-background-gray'>
                <p>
                  <strong>
                    You can download and install FiveM from FiveM.net
                  </strong>
                </p>
                <a
                  href='https://fivem.net/'
                  target='_blank'
                  rel='noreferrer noopener'
                  className='button is-medium is-info'
                >
                  Download FiveM
                </a>
              </div>
            </div>
            <div className='column is-one-third'>
              <h3>For Linux / macOS</h3>
              <p>
                FiveM is currently only available for Windows, and is not
                compatible with Linux/Wine, or macOS.
              </p>
            </div>
          </div>
        </GuideContent>

        <hr />
        <GuideContent id='is-fivem-safe' title='Is FiveM&reg; Safe To Install?'>
          <p>
            <strong>Yes, FiveM&reg; is safe to install.</strong> The project's
            source code is openly available on GitHub. If your computer is
            running into trouble launching FiveM because of your anti-virus,
            read our guide on how you can{" "}
            <a
              href={`/guides/${Guide.COMMON_FIVEM_ISSUES_GUIDE}/#${Solution.DISABLE_AV}`}
            >
              temporarily disable it here
            </a>
            .
          </p>
        </GuideContent>
      </GuideContentSection>
    </GuidePage>
  )
}

export default WhatIsFiveMGuide
