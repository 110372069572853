import { FunctionalComponent, h } from "preact"
import Snail from "../header/snaily.png"
import ExternalLink from "../ExternalLink"

const Footer: FunctionalComponent = () => {
  return (
    <footer class='footer'>
      <div class='columns is-multiline has-text-centered-mobile'>
        <div class='column is-4'>
          <img
            src={Snail}
            alt='Snaily is pleased you reached the bottom'
            height={128}
          />
          <div>
            <p class='is-size-5'>
              <strong class='has-text-white'>
                Copyright &copy; 2022 <a href='/'>FiveM.guide</a>
              </strong>
            </p>
            <small>All Rights Reserved</small>
          </div>
        </div>
        <div class='column is-3'>&nbsp;</div>
        <div class='column is-5 has-text-right has-text-centered-mobile'>
          <br />
          <strong class='is-size-5 has-text-white'>
            We are not affiliated with, endorsed by, or sponsored by Cfx.re,
            ZAP-Hosting GmbH, or Take-Two Interactive Software.
          </strong>
          <br />
          <br />
          <p class='is-size-6 mt-2'>
            <u>FiveM</u>&reg; is a registered trademark of{" "}
            <u>ZAP-Hosting GmbH</u>.&nbsp;
            <u>Grand Theft Auto 5</u>&reg; is a registered trademark of{" "}
            <u>Take-Two Interactive</u>
            <br />
          </p>
          <br />
          <em class='has-text-white'>
            The Snaily artwork belongs to{" "}
            <ExternalLink href='https://teamsnaily.com'>
              Team Snaily
            </ExternalLink>{" "}
            and the Emojitwo project.
          </em>
        </div>
      </div>
    </footer>
  )
}

export default Footer
