import { FunctionalComponent, h } from "preact"

const Figure: FunctionalComponent<{ label?: string }> = ({
  children,
  label
}) => {
  return (
    <figure class='has-text-centered'>
      {children}
      {label && <label class='label mt-2'>{label}</label>}
    </figure>
  )
}

export default Figure
