import { FunctionalComponent, h } from "preact"
import styles from "./style.scss"

const LongAnswer: FunctionalComponent = ({ children }) => {
  return (
    <div class='p-4 mb-4'>
      <h3 class={"is-size-5 " + styles.long_answer}>{children}</h3>
    </div>
  )
}

export default LongAnswer
