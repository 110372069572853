
		import Async from "../../../node_modules/.pnpm/@preact+async-loader@3.0.1_preact@10.6.4/node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!../../../node_modules/.pnpm/babel-loader@8.2.3_ecbaa735a1342739b508fb94bc4fd047/node_modules/babel-loader/lib/index.js??ref--4!./index.tsx");
				typeof cb === 'function' && cb(result);
			}, "route-notfound");
		}

		export default Async(load);
	