import { FunctionalComponent, h } from "preact"
import SolutionPage from "../../components/page/SolutionPage"
import InflateFailedSolution from "../../components/page/solution/common-errors/InflateFailedSolution"

const InflationFailedSolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title={'Fixing "Failed to call inflate()"'}
      description='This crash is caused by an invalid resource asset'
    >
      <InflateFailedSolution />
    </SolutionPage>
  )
}

export default InflationFailedSolutionPage
