import Question from "../Question"
import QuestionDescription from "../QuestionDescription"
import { FunctionalComponent, h } from "preact"
import { Solution } from "../types"
import DoNotBlameFiveM from "../DoNotBlameFiveM"
import SolutionBox from "../answer/SolutionBox"
import SolutionArea from "../answer/SolutionArea"
import Symptoms from "../answer/Symptoms"
import SolutionList from "../answer/SolutionList"
import Solutions from "../answer/Solutions"
import SolutionSubList from "../answer/SolutionSubList"
import ExternalLink from "../../../ExternalLink"

const DisableAVSolution: FunctionalComponent = () => {
  const falsePositiveURL =
    "https://www.pandasecurity.com/en/mediacenter/security/false-positives-what-are-they/" +
    "#:~:text=A%20false%20positive%20occurs%20when,scanning%20or%20analysis%20of%20behavior."
  const trendMicroURL =
    "https://en.community.trendmicro.com/conversations/pc-security/" +
    "how-to-temporary-disable-protection/5da6f842762ed63ffc893cc8?commentId=5da6f894762ed63ffc8958d4"
  const malwareBytesURL =
    "https://support.malwarebytes.com/hc/en-us/articles/360038984793-Real-Time-Protection-in-Malwarebytes-for-Windows"

  return (
    <Question
      title='Your anti-virus is preventing FiveM&reg; from running'
      id={Solution.DISABLE_AV}
      pre={<DoNotBlameFiveM />}
    >
      <QuestionDescription>
        Often an anti-virus will detect FiveM&reg; as a&nbsp;
        <ExternalLink href={falsePositiveURL}>false-positive</ExternalLink>.
      </QuestionDescription>

      <div class='notification is-info'>
        <p>
          <strong>FiveM is an open-source project</strong> and does not contain
          malicious code. It often triggers anti-viruses, due to how it
          initializes GTA V.
        </p>
      </div>

      <SolutionArea>
        <SolutionBox>
          <Symptoms />
          <SolutionList
            items={[
              <span>
                The FiveM&reg; installer is freezing or closing without notice
              </span>,
              <span>Your anti-virus is blocking the FiveM&reg; download</span>,
              <span>
                Your anti-virus is reporting <strong>FiveM.exe</strong> as a
                virus
              </span>,
              <span>FiveM uninstalls itself after installing it</span>,
              <span>
                Stuck on{" "}
                <em>"We're getting there and it will be worth the wait"</em>
              </span>,
              <span>
                <code>Moving of [file].exe failed (err = 32)</code>
              </span>
            ]}
          />
        </SolutionBox>
        <SolutionBox solution>
          <Solutions />
          <SolutionList
            solution
            items={[
              <span>Make sure all FiveM&reg; processes are closed</span>,
              <span>
                <strong>
                  Windows&reg; Defender should not interfere with FiveM&reg;
                  launching.
                </strong>
                {/*<strong>If you have only Windows Defender</strong>, follow these*/}
                {/*steps:*/}
                {/*<SolutionSubList*/}
                {/*  ordered*/}
                {/*  items={[*/}
                {/*    <span>*/}
                {/*      Open the start menu, and search for{" "}*/}
                {/*      <strong>"Windows Security"</strong>*/}
                {/*    </span>,*/}
                {/*    <span>*/}
                {/*      Click on <strong>"Virus &amp; Threat Protection"</strong>*/}
                {/*    </span>,*/}
                {/*    <span>*/}
                {/*      Scroll down, and click on{" "}*/}
                {/*      <strong>"Ransomware protection"</strong>*/}
                {/*    </span>,*/}
                {/*    <span>*/}
                {/*      <em>*/}
                {/*        <u>Temporarily</u>*/}
                {/*      </em>{" "}*/}
                {/*      disable ransomware protection*/}
                {/*    </span>*/}
                {/*  ]}*/}
                {/*/>*/}
              </span>,
              <span>
                <strong>If you have a third-party anti-virus</strong>,{" "}
                <em>
                  <u>temporarily</u>
                </em>{" "}
                disable it.
                <br />
                <SolutionSubList
                  items={[
                    <ExternalLink href='https://support.avast.com/en-us/article/Antivirus-shield-settings/#idt_010'>
                      Temporarily disable <strong>Avast</strong>&reg;
                    </ExternalLink>,
                    <ExternalLink href='https://support.norton.com/sp/en/us/home/current/solutions/v116457581#twiststart_'>
                      Temporarily disable <strong>Norton</strong>&reg;
                    </ExternalLink>,
                    <ExternalLink href='https://www.wikihow.com/Disable-McAfee'>
                      Temporarily disable <strong>McAfee</strong>&reg;
                    </ExternalLink>,
                    <ExternalLink href='https://support.kaspersky.com/KIS/2019/en-US/70886.htm'>
                      Temporarily disable <strong>Kaspersky</strong>&reg;
                    </ExternalLink>,
                    <ExternalLink href={trendMicroURL}>
                      Temporarily disable <strong>Trend Micro</strong>&reg;
                    </ExternalLink>,
                    <ExternalLink href={malwareBytesURL}>
                      Temporarily disable <strong>Malwarebytes</strong>&reg;
                    </ExternalLink>
                  ]}
                />
              </span>,
              <span class='pt-1'>
                Retry starting FiveM, or re-download <code>FiveM.exe</code> and
                run the installer again.
              </span>,
              <span>
                Once FiveM&reg; is installed and running, make sure to{" "}
                <strong>re-enable your anti-virus software</strong>.
              </span>
            ]}
          />
        </SolutionBox>
      </SolutionArea>
    </Question>
  )
}

export default DisableAVSolution
