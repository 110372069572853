import { FunctionalComponent, h } from "preact"
import SolutionPage from "../../components/page/SolutionPage"
import CurlTimeoutSolution from "../../components/page/solution/common-errors/CurlTimeoutSolution"

const CurlTimeoutSolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title='How to Fix Curl Connection Errors in FiveM&reg;'
      description='CURL errors are typically connection errors'
    >
      <CurlTimeoutSolution />
    </SolutionPage>
  )
}

export default CurlTimeoutSolutionPage
