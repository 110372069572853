import { FunctionalComponent, h } from "preact"

const ExternalLink: FunctionalComponent<{ href: string }> = ({
  children,
  href
}) => {
  return (
    <a href={href} rel='noreferrer' target='_blank'>
      {children}
    </a>
  )
}

export default ExternalLink
