import SolutionPage from "../../components/page/SolutionPage"
import D3d11Solution from "../../components/page/solution/common-errors/D3d11Solution"
import { h, FunctionalComponent } from "preact"
import LongAnswer from "../../components/page/solution/LongAnswer"

const D3D11SolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title='How to fix d3d11.dll errors'
      description='Learn how to remove d3d11.dll to fix unexpected crashes'
    >
      <br />
      <br />
      <h2 class='is-size-2'>
        <strong>What is d3d11.dll?</strong>
      </h2>
      <LongAnswer>
        <code>d3d11.dll</code> is a library driver for interacting with the{" "}
        <strong>DirectX 11 API</strong>. While it is not included by default
        with Grand Theft Auto V or FiveM, it is commonly included as a part of
        custom graphics mods.
      </LongAnswer>

      <D3d11Solution />
    </SolutionPage>
  )
}

export default D3D11SolutionPage
