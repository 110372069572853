import SolutionPage from "../../components/page/SolutionPage"
import RPFSolution from "../../components/page/solution/common-errors/RPFSolution"
import { h, FunctionalComponent } from "preact"
import ExternalLink from "../../components/ExternalLink"

const RPFSolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title='Fixing Rage Package File (.RPF) errors'
      description='How to fix invalid GTA V resource errors'
    >
      <br />

      <h2 class='is-size-2'>
        <strong>About .RPF Files</strong>
      </h2>

      <br />
      <p>
        A <strong>RPF</strong> file is a <strong>Rage Package File</strong>, an
        archive of game assets used by Rockstar's game engine &mdash; Rage. The
        most common utility for modifying and editing .RPF files is&nbsp;
        <ExternalLink href='https://openiv.com/'>OpenIV</ExternalLink>.
      </p>

      <br />
      <p>
        Rockstar distributes updates and game content in these files, so when
        these files are modified, it may cause runtime errors when playing FiveM
        or GTA V.
      </p>

      <br />
      <br />
      <h2 class='is-size-2'>
        <strong>Common Issues</strong>
      </h2>
      <RPFSolution />
    </SolutionPage>
  )
}

export default RPFSolutionPage
