import { h, FunctionalComponent } from "preact"
import SolutionPage from "../../components/page/SolutionPage"
import NoSteamIDSolution from "../../components/page/solution/common-errors/NoSteamIDSolution"

const NoSteamIDSolutionPage: FunctionalComponent = () => {
  return (
    <SolutionPage
      title='How to Fix Steam ID Not Found'
      description='FiveM is unable to detect the Steam client running on your PC'
    >
      <NoSteamIDSolution />
    </SolutionPage>
  )
}

export default NoSteamIDSolutionPage
