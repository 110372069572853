import { FunctionalComponent, h } from "preact"
import styles from "./style.scss"

type Props = {
  id: string
  title: string
  sub?: boolean
  class?: string
  noTopMargin?: boolean
  noIndent?: boolean
}
const GuideContent: FunctionalComponent<Props> = ({
  title,
  id,
  sub,
  children,
  noTopMargin,
  noIndent,
  class: classes
}) => {
  const classList = [classes]
  if (!noTopMargin) classList.push("mt-5")
  const headerBody = (
    <span>
      {title}
      <sup>
        &nbsp;
        <a
          class='has-text-grey'
          style={{ textDecoration: "none" }}
          href={`#${id}`}
        >
          #
        </a>
      </sup>
    </span>
  )

  return (
    <div class={classList.join(" ")} id={id}>
      <div class={styles.guide_content_header}>
        {sub ? (
          <h3 class='is-size-3'>{headerBody}</h3>
        ) : (
          <h2 class='is-size-2'>{headerBody}</h2>
        )}
      </div>
      <div class={"is-size-5" + (noIndent ? "" : " ml-4")}>{children}</div>
    </div>
  )
}

export default GuideContent
