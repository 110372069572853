import { FunctionalComponent, h } from "preact"
import { Route, Router } from "preact-router"

import Home from "../routes/home"
import NotFoundPage from "../routes/notfound"
import Header from "./header"
import IntroToGTARPGuide from "../routes/guides/gta-rp-guide/IntroToGTARPGuide"
import GuidesHome from "../routes/guides"
import CommonFiveMIssuesGuide from "../routes/guides/common-issues-guide/CommonFiveMIssuesGuide"
import DisablingAVSolutionPage from "../routes/solutions/DisablingAVSolutionPage"
import CurlTimeoutSolutionPage from "../routes/solutions/CurlTimeoutSolutionPage"
import ROSTimeoutSolutionPage from "../routes/solutions/ROSTimeoutSolutionPage"
import CanarySolutionPage from "../routes/solutions/CanarySolutionPage"
import PackfileSolutionPage from "../routes/solutions/PackfileSolutionPage"
import InvalidFixupSolutionPage from "../routes/solutions/InvalidFixupSolutionPage"
import MissingDLLSolutionPage from "../routes/solutions/MissingDLLSolutionPage"
import D3D11SolutionPage from "../routes/solutions/D3D11SolutionPage"
import InflateFailedSolutionPage from "../routes/solutions/InflateFailedSolutionPage"
import RPFSolutionPage from "../routes/solutions/RPFSolutionPage"
import NoSteamIDSolutionPage from "../routes/solutions/NoSteamIDSolutionPage"
import Footer from "./footer/Footer"
import { Solution } from "./page/solution/types"
import { Guide } from "./page/guide/types"
import HowToRepairGTAGuide from "../routes/guides/how-to-repair-gtav/HowToRepairGTAGuide"
import CfxBannedSolutionPage from "../routes/solutions/CfxBannedSolutionPage"
import WhatIsFiveMGuide from "../routes/guides/what-is-fivem/WhatIsFiveMGuide"
import OutOfDateGameSolutionPage from "../routes/solutions/OutOfDateGameSolutionPage"

const App: FunctionalComponent = () => {
  return (
    <div id='app'>
      <Header />
      <Router>
        <Route path='/' component={Home} />
        {/* Solutions */}
        <Route
          path={`/solutions/${Solution.DISABLE_AV}/`}
          component={DisablingAVSolutionPage}
        />
        <Route
          path={`/solutions/${Solution.CURL_TIMEOUT}/`}
          component={CurlTimeoutSolutionPage}
        />
        <Route
          path={`/solutions/${Solution.ROS_MTL_FAILED}/`}
          component={ROSTimeoutSolutionPage}
        />
        <Route
          path={`/solutions/${Solution.DISABLE_CANARY}/`}
          component={CanarySolutionPage}
        />
        <Route
          path={`/solutions/${Solution.PACKFILE_SSL_READ_ERROR}/`}
          component={PackfileSolutionPage}
        />
        <Route
          path={`/solutions/${Solution.INVALID_FIXUP}/`}
          component={InvalidFixupSolutionPage}
        />
        <Route
          path={`/solutions/${Solution.MISSING_DLLS}/`}
          component={MissingDLLSolutionPage}
        />
        <Route
          path={`/solutions/${Solution.D3D11_DLL}/`}
          component={D3D11SolutionPage}
        />
        <Route
          path={`/solutions/${Solution.INFLATE_FAILED}/`}
          component={InflateFailedSolutionPage}
        />
        <Route
          path={`/solutions/${Solution.INVALID_RPF}/`}
          component={RPFSolutionPage}
        />
        <Route
          path={`/solutions/${Solution.STEAM_NOT_FOUND}/`}
          component={NoSteamIDSolutionPage}
        />
        <Route
          path={`/solutions/${Solution.CFX_BANNED}/`}
          component={CfxBannedSolutionPage}
        />
        <Route
          path={`/solutions/${Solution.OUT_OF_DATE}/`}
          component={OutOfDateGameSolutionPage}
        />

        {/* Guides */}
        <Route path='/guides/' component={GuidesHome} />
        <Route
          path={`/guides/${Guide.WHAT_IS_FIVEM_GUIDE}/`}
          component={WhatIsFiveMGuide}
        />
        <Route
          path={`/guides/${Guide.COMMON_FIVEM_ISSUES_GUIDE}/`}
          component={CommonFiveMIssuesGuide}
        />
        <Route
          path={`/guides/${Guide.INTRO_TO_GTA_RP_GUIDE}/`}
          component={IntroToGTARPGuide}
        />
        <Route
          path={`/guides/${Guide.HOW_TO_REPAIR_GTAV}/`}
          component={HowToRepairGTAGuide}
        />
        <NotFoundPage default />
      </Router>
      <Footer />
    </div>
  )
}

export default App
