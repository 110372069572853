import { FunctionalComponent, h } from "preact"
import styles from "./style.scss"

interface Props {
  ordered?: boolean
  solution?: boolean
  items: JSX.Element[]
}

const SolutionList: FunctionalComponent<Props> = ({
  ordered,
  items,
  solution
}) => {
  const solutionItemClass = styles.solution_item
  const renderItem = (e: JSX.Element) => (
    <li>
      <h4 class={solutionItemClass}>{e}</h4>
    </li>
  )
  return (
    <div class='content'>
      {ordered ? (
        <ol {...(solution && { itemProp: "text" })}>{items.map(renderItem)}</ol>
      ) : (
        <ul {...(solution && { itemProp: "text" })}>{items.map(renderItem)}</ul>
      )}
    </div>
  )
}

export default SolutionList
