export enum Solution {
  OUT_OF_DATE = "dlc-files-are-missing-or-corrupted",
  MISSING_DLLS = "missing-dll",
  DISABLE_AV = "how-to-temporarily-disable-av",
  DISABLE_CANARY = "how-to-disable-fivem-canary",
  D3D11_DLL = "d3d11-dll",
  INFLATE_FAILED = "failed-to-inflate",
  ROS_MTL_FAILED = "how-to-fix-ros-mtl-timeout-errors",
  CURL_TIMEOUT = "how-to-fix-curl-timeout",
  PACKFILE_SSL_READ_ERROR = "how-to-fix-packfile-ssl-read-error",
  PACKFILE_PARTIAL_FILE = "how-to-fix-packfile-partial-file",
  PACKFILE_FAILED_TO_SAVE = "how-to-fix-packfile-failed-to-add-entry-localstorage",
  INVALID_FIXUP = "invalid-fixup",
  INVALID_RPF = "invalid-rpf",
  STEAM_NOT_FOUND = "how-to-fix-unable-to-find-steamid",
  REPAIR_FILES = "repair-files",
  CFX_BANNED = "banned-from-the-cfx-re-network"
}
