import { FunctionalComponent, h } from "preact"
import Navbar from "./Navbar"

const Header: FunctionalComponent = () => {
  return (
    <header>
      <Navbar />
    </header>
  )
}

export default Header
