import { h, FunctionalComponent } from "preact"
import { Solution } from "../types"
import SolutionBox from "../answer/SolutionBox"
import QuestionDescription from "../QuestionDescription"
import Question from "../Question"
import ExternalLink from "../../../ExternalLink"
import DoNotBlameFiveM from "../DoNotBlameFiveM"
import LongAnswer from "../LongAnswer"
import SolutionArea from "../answer/SolutionArea"
import Solutions from "../answer/Solutions"
import Symptoms from "../answer/Symptoms"
import SolutionList from "../answer/SolutionList"

const ROSSolution: FunctionalComponent = () => {
  const externalUrl =
    "https://bmmagazine.co.uk/business/grand-theft-auto-update-banned-in-over-50-countries/"
  return (
    <Question
      title='Timed out while waiting for ROS/MTL to clear launch'
      id={Solution.ROS_MTL_FAILED}
      pre={<DoNotBlameFiveM network />}
    >
      <QuestionDescription>
        Your computer is failing to connect to Rockstar Online services
      </QuestionDescription>
      <LongAnswer>
        <strong>
          Your computer is not able to connect to Rockstar Online Services
          properly.
        </strong>{" "}
        In some countries,{" "}
        <ExternalLink href={externalUrl}>
          Rockstar Online services are unavailable.
        </ExternalLink>
      </LongAnswer>
      <br />

      <SolutionArea>
        <SolutionBox>
          <Symptoms />
          <SolutionList
            items={[
              <code>
                Timed out while waiting for ROS/MTL to clear launch. Please
                check your system for third-party software (antivirus, etc.)
                that might be interfering with ROS.
              </code>
            ]}
          />
        </SolutionBox>

        <SolutionBox>
          <Solutions />
          <SolutionList
            items={[
              <span>
                If Rockstar is unavailable in your country, you may have to use
                a VPN service to play.
              </span>,
              <span>
                <a href={`#${Solution.DISABLE_AV}`}>
                  Ensure your anti-virus is not blocking your connection
                </a>
              </span>,
              <span>Try restarting your PC and retry connecting</span>,
              <span>
                Try re-installing the <strong>Rockstar Games Launcher</strong>
              </span>,
              <span>
                <ExternalLink href='https://support.rockstargames.com/servicestatus'>
                  Check the status
                </ExternalLink>{" "}
                of Rockstar Online Services
              </span>
            ]}
          />
        </SolutionBox>
      </SolutionArea>
    </Question>
  )
}

export default ROSSolution
