import { h, FunctionalComponent } from "preact"
import GuidePage from "../../../components/page/GuidePage"
import GuideSection from "../../../components/page/guide/GuideSection"
import ROSSolution from "../../../components/page/solution/common-errors/ROSSolution"
import CanarySolution from "../../../components/page/solution/common-errors/CanarySolution"
import DisableAVSolution from "../../../components/page/solution/common-errors/DisableAVSolution"
import MissingDLLSolution from "../../../components/page/solution/common-errors/MissingDLLSolution"
import CurlTimeoutSolution from "../../../components/page/solution/common-errors/CurlTimeoutSolution"
import D3d11Solution from "../../../components/page/solution/common-errors/D3d11Solution"
import NoSteamIDSolution from "../../../components/page/solution/common-errors/NoSteamIDSolution"
import InvalidFixupSolution from "../../../components/page/solution/common-errors/InvalidFixupSolution"
import InflateFailedSolution from "../../../components/page/solution/common-errors/InflateFailedSolution"
import ExternalLink from "../../../components/ExternalLink"
import MidContentAd from "../../../components/ads/MidContentAd"

const CommonFiveMIssuesGuide: FunctionalComponent = () => {
  return (
    <GuidePage
      title='Common FiveM&reg; Issues Guide'
      description='A guide to fixing the most common FiveM&reg; issues'
    >
      <header class='section pt-2 pb-2'>
        <div class='has-text-centered'>
          <h2 class='is-size-5'>
            This covers some of the common issues you may encounter with the
            FiveM&reg; client.
          </h2>
        </div>
      </header>
      <hr />

      <section class='section'>
        <GuideSection id='first-steps'>
          <strong>Common Problems</strong>
        </GuideSection>
        <p class='is-size-4'>
          First,{" "}
          <ExternalLink href='https://docs.fivem.net/docs/support/client-issues/'>
            check the official FiveM&reg; docs
          </ExternalLink>{" "}
          to see if your issue is mentioned there.
          <br />
          <br />
          If you don't see your issue, begin at the start of this document, and
          scroll through each of the issues to find the error you are
          experiencing.
        </p>

        <DisableAVSolution />
        <NoSteamIDSolution />
        <MidContentAd />

        <MissingDLLSolution />
        <D3d11Solution />
        <MidContentAd />

        <CurlTimeoutSolution />
        <ROSSolution />
        <MidContentAd />

        <InflateFailedSolution />
        <InvalidFixupSolution />
        <CanarySolution />
        <MidContentAd />
      </section>
    </GuidePage>
  )
}

export default CommonFiveMIssuesGuide
