import { FunctionalComponent, h } from "preact"
import Question from "../Question"
import QuestionDescription from "../QuestionDescription"
import { Solution } from "../types"
import DoNotBlameFiveM from "../DoNotBlameFiveM"
import SolutionBox from "../answer/SolutionBox"
import LongAnswer from "../LongAnswer"
import ExternalLink from "../../../ExternalLink"
import SolutionArea from "../answer/SolutionArea"
import Symptoms from "../answer/Symptoms"
import Solutions from "../answer/Solutions"
import SolutionList from "../answer/SolutionList"
import SolutionSubList from "../answer/SolutionSubList"

const NoSteamIDSolution: FunctionalComponent = () => {
  return (
    <Question
      title='Unable to find SteamID, please relaunch FiveM'
      id={Solution.STEAM_NOT_FOUND}
      pre={<DoNotBlameFiveM />}
    >
      <QuestionDescription>
        Your Steam client is not running
      </QuestionDescription>
      <LongAnswer>
        The server you are connecting to requires a Steam ID, but the Steam
        service is not running on your computer.
      </LongAnswer>

      <SolutionArea>
        <SolutionBox>
          <Symptoms />
          <SolutionList
            items={[
              <span>
                <code>
                  Unable to find SteamID, please relaunch FiveM&reg; with steam
                  open or restart FiveM&reg; & Steam if steam is already open
                </code>
              </span>,
              <span>
                <ExternalLink href='https://steamstat.us/'>
                  Steam services may be temporarily unavailable
                </ExternalLink>
                , check the status and try again later
              </span>,
              <span>
                The server does not have a properly configured{" "}
                <code>steam_webApiKey</code> value in it's configuration.
              </span>
            ]}
          />
        </SolutionBox>
        <SolutionBox>
          <Solutions />
          <SolutionList
            ordered
            items={[
              <span>
                <strong>For Players:</strong>
                <SolutionSubList
                  items={[
                    <span>Close all running FiveM&reg; processes</span>,
                    <span>Start Steam, and wait for it to start fully</span>,
                    <span>
                      Relaunch FiveM, and retry connecting to the server
                    </span>,
                    <span>
                      FiveM should be able to read your Steam identifiers
                      properly now
                    </span>
                  ]}
                />
              </span>,
              <span>
                <strong>For Server Owners:</strong>
                <SolutionSubList
                  items={[
                    <span>
                      Ensure that you've set a proper API key in your{" "}
                      <code>server.cfg</code>, for the
                      <code>steam_webApiKey</code> value.
                    </span>,
                    <span>
                      For more information, view the{" "}
                      <ExternalLink href='https://docs.fivem.net/docs/server-manual/setting-up-a-server/'>
                        official FiveM&reg; guide
                      </ExternalLink>{" "}
                      on setting up a server, and search for "Steam"
                    </span>
                  ]}
                />
              </span>
            ]}
          />
        </SolutionBox>
      </SolutionArea>
    </Question>
  )
}

export default NoSteamIDSolution
