import { FunctionalComponent, h } from "preact"
import Page from "./Page"
import Hero from "../bulma/Hero"
import MidContentAd from "../ads/MidContentAd"

interface Props {
  title: string
  description: string
}

const SolutionPage: FunctionalComponent<Props> = ({
  title,
  description,
  children
}) => {
  return (
    <Page
      pre={<Hero title={title} description={description} class='is-primary' />}
    >
      <article
        class='section pt-4'
        itemScope
        itemType='https://schema.org/Guide'
      >
        <meta itemProp='about' content={title} />
        <meta itemProp='name' content={description} />
        <nav
          class='mt-0 mb-0 is-centered has-arrow-separator breadcrumb'
          aria-label='breadcrumbs'
        >
          <ul>
            <li>
              <a>Solutions</a>
            </li>
            <li class='is-active'>
              <strong>
                <a href='#' aria-current='page'>
                  {title}
                </a>
              </strong>
            </li>
          </ul>
        </nav>

        <div itemProp='text'>{children}</div>

        <MidContentAd />
        <br />
      </article>
    </Page>
  )
}

export default SolutionPage
